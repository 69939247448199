import Blog1 from "../../../assets/img/car-finder/blog/04.jpg";
import Blog2 from "../../../assets/img/car-finder/blog/05.jpg";
import Blog3 from "../../../assets/img/car-finder/blog/06.jpg";
import Avatar from "../../../assets/img/avatars/01.jpg"

function LatestNews() {
  return (
    <section className="container pb-4 pb-sm-5 mb-2 mb-md-4 mt-5">
      <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-sm-4 pb-sm-2">
        <h2 className="h3 mb-2 mb-sm-0">Latest news</h2>
        <a
          className="btn btn-link btn-light fw-normal px-0"
          href="#"
        >
          Go to blog<i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
        </a>
      </div>
      <div className="row">
        <div className="col-md-4">
          <a
            className="d-block rounded-3 overflow-hidden"
            href="#"
          >
            <img className="d-block" src={Blog3} alt="Image" />
          </a>
          <div className="py-3">
            <a className="fs-xs text-uppercase text-decoration-none" href="#">
              Events
            </a>
            <h3 className="fs-base pt-1">
              <a className="nav-link" href="#">
                Top 10 Classic Car Events
              </a>
            </h3>
            <a className="d-flex align-items-center text-decoration-none" href="#">
              <img
                className="rounded-circle"
                src={Avatar}
                width="44"
                alt="Avatar"
              />
              <div className="ps-2">
                <h6 className="fs-sm lh-base mb-1">Jacob Jones</h6>
                <div className="d-flex fs-xs opacity-60">
                  <span className="me-2 pe-1">
                    <i className="fi-calendar-alt me-1"></i>May 8
                  </span>
                  <span>
                    <i className="fi-chat-circle me-1"></i>No comments
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <a
            className="d-block rounded-3 overflow-hidden"
            href="#"
          >
            <img className="d-block" src={Blog2} alt="Image" />
          </a>
          <div className="py-3">
            <a className="fs-xs text-uppercase text-decoration-none" href="#">
              Events
            </a>
            <h3 className="fs-base pt-1">
              <a className="nav-link" href="#">
                Top 10 Classic Car Events
              </a>
            </h3>
            <a className="d-flex align-items-center text-decoration-none" href="#">
              <img
                className="rounded-circle"
                src={Avatar}
                width="44"
                alt="Avatar"
              />
              <div className="ps-2">
                <h6 className="fs-sm lh-base mb-1">Jacob Jones</h6>
                <div className="d-flex fs-xs opacity-60">
                  <span className="me-2 pe-1">
                    <i className="fi-calendar-alt me-1"></i>May 8
                  </span>
                  <span>
                    <i className="fi-chat-circle me-1"></i>No comments
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <a
            className="d-block rounded-3 overflow-hidden"
            href="#"
          >
            <img className="d-block" src={Blog1} alt="Image" />
          </a>
          <div className="py-3">
            <a className="fs-xs text-uppercase text-decoration-none" href="#">
              Events
            </a>
            <h3 className="fs-base pt-1">
              <a className="nav-link" href="#">
                Top 10 Classic Car Events
              </a>
            </h3>
            <a className="d-flex align-items-center text-decoration-none" href="#">
              <img
                className="rounded-circle"
                src={Avatar}
                width="44"
                alt="Avatar"
              />
              <div className="ps-2">
                <h6 className="fs-sm lh-base mb-1">Jacob Jones</h6>
                <div className="d-flex fs-xs opacity-60">
                  <span className="me-2 pe-1">
                    <i className="fi-calendar-alt me-1"></i>May 8
                  </span>
                  <span>
                    <i className="fi-chat-circle me-1"></i>No comments
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestNews;
