import LatestCars from "../homepage/components/LatestCars";
import LatestNews from "../homepage/components/LatestNews";
import TopOffers from "../homepage/components/TopOffers";

function SellCarPage() {
  return (
    <>
      <div className="container mt-5 mb-md-4 py-5">
        <div className="row">
          {/* <!-- Page content--> */}
          <div className="col-lg-8">
            {/* <!-- Breadcrumb--> */}
            <nav className="mb-3 pt-md-3" aria-label="Breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Sell car
                </li>
              </ol>
            </nav>
            {/* <!-- Title--> */}
            <div className="mb-4">
              <h1 className="h2  mb-0">Sell car</h1>
              <div className="d-lg-none  pt-3 mb-2">80% content filled</div>
              <div
                className="progress d-lg-none mb-4"
                style={{
                  height: ".25rem",
                }}
              >
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: "80%" }}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            {/* <!-- Basic info--> */}
            <section
              className="card card-body border-0 bg-gray p-4 mb-4"
              id="basic-info"
            >
              <h2 className="h4 mb-4">
                <i className="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>Basic
                info
              </h2>
              <div className="mb-3">
                <label className="form-label" htmlFor="sc-title">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="sc-title"
                  placeholder="Title for your property"
                  defaultValue="Mercedes-Benz E 400 Cabriolet"
                  required
                />
                <span className="form-text opacity-50">48 characters left</span>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="sc-condition">
                    Vehicle condition <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    id="sc-condition"
                    required
                  >
                    <option value="" disabled hidden>
                      Choose condition
                    </option>
                    <option value="Used">Used</option>
                    <option value="New">New</option>
                  </select>
                </div>
              </div>
              <div className="form-label fw-bold pt-3 pb-2">
                Are you listing on BidCars as part of a company?
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="sc-dealer"
                  name="sc-seller-type"
                />
                <label className="form-check-label" htmlFor="sc-dealer">
                  I am a registered dealer
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="sc-individual"
                  name="sc-seller-type"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="sc-individual">
                  I am a private seller
                </label>
              </div>
            </section>
            {/* <!-- Price--> */}
            <section
              className="card border-0 bg-gray card-body p-4 mb-4"
              id="price"
            >
              <h2 className="h4 mb-4">
                <i className="fi-cash text-primary fs-5 mt-n1 me-2"></i>Price
              </h2>
              <label className="form-label" htmlFor="sc-price">
                Price <span className="text-danger">*</span>
              </label>
              <div className="d-sm-flex mb-2">
                <select className="form-select w-25 me-2 mb-2">
                  <option value="usd">&#36;</option>
                  <option value="eur">&#8364;</option>
                  <option value="gbp">&#163;</option>
                  <option value="jpy">&#165;</option>
                </select>
                <input
                  className="form-control w-100 me-2 mb-2"
                  type="number"
                  id="sc-price"
                  min="200"
                  step="50"
                  defaultValue="31900"
                  required
                />
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="negotiated-price"
                />
                <label className="form-check-label" htmlFor="negotiated-price">
                  Negotiated price
                </label>
              </div>
            </section>
            {/* <!-- Vehicle information--> */}
            <section
              className="card border-0 bg-gray card-body p-4 mb-4"
              id="vehicle-info"
            >
              <h2 className="h4 mb-4">
                <i className="fi-car text-primary fs-5 mt-n1 me-2"></i>Vehicle
                information
              </h2>
              <div className="row pb-2">
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="sc-make">
                    Make <span className="text-danger">*</span>
                  </label>
                  <select className="form-select" defaultValue="Mercedes-Benz" id="sc-make">
                    <option value="" disabled>
                      Select make
                    </option>
                    <option value="Acura">Acura</option>
                    <option value="BMW">BMW</option>
                    <option value="Citroen">Citroen</option>
                    <option value="Lexus">Lexus</option>
                    <option value="Mercedes-Benz">
                      Mercedes-Benz
                    </option>
                    <option value="Nissan">Nissan</option>
                    <option value="Toyota">Toyota</option>
                  </select>
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label" htmlFor="sc-model">
                    Model <span className="text-danger">*</span>
                  </label>
                  <select className="form-select" defaultValue="E 400" id="sc-model">
                    <option value="" disabled>
                      Select model
                    </option>
                    <option value="A-Class">A-Class</option>
                    <option value="B-Class">B-Class</option>
                    <option value="C-Class">C-Class</option>
                    <option value="E 220">E 220</option>
                    <option value="E 400">
                      E 400
                    </option>
                    <option value="GLS 550">GLS 550</option>
                    <option value="GLE-Class">GLE-Class</option>
                    <option value="ML 350">ML 350</option>
                    <option value="Viano">Viano</option>
                  </select>
                </div>
                <div className="col-md-3 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="sc-year">
                    Year <span className="text-danger">*</span>
                  </label>
                  <select className="form-select" defaultValue="2018" id="sc-year">
                    <option value="" disabled>
                      Select year
                    </option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">
                      2018
                    </option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                  </select>
                </div>
                <div className="col-md-3 col-sm-6 mb-3">
                  <label className="form-label" htmlFor="sc-mileage">
                    Mileage <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="sc-mileage"
                    min="200"
                    step="100"
                    defaultValue="25000"
                    placeholder="Enter mileage"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label" htmlFor="sc-vin">
                    VIN
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sc-vin"
                    defaultValue="2VW821AU9JM754284"
                    placeholder="Enter VIN code"
                  />
                </div>
              </div>
              <div className="border-top border-light mt-2 pt-4">
                <div className="row pb-2">
                  <div className="col-md-6">
                    <label className="form-label" htmlFor="sc-body">
                      Body type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select mb-3"
                      defaultValue=""
                      id="sc-body"
                    >
                      <option value="Convertible" disabled>
                        Select body type
                      </option>
                      <option value="Compact">Compact</option>
                      <option value="Convertible">
                        Convertible
                      </option>
                      <option value="Crossover">Crossover</option>
                      <option value="Coupe">Coupe</option>
                      <option value="Family MPV">Family MPV</option>
                      <option value="Pickup">Pickup</option>
                      <option value="Sedan">Sedan</option>
                      <option value="SUV">SUV</option>
                      <option value="Wagon">Wagon</option>
                    </select>
                    <label className="form-label " htmlFor="sc-fuel">
                      Fuel type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select mb-3"
                      id="sc-fuel"
                      defaultValue="Gasoline"
                    >
                      <option value="" disabled>
                        Select fuel type
                      </option>
                      <option value="Gasoline">
                        Gasoline
                      </option>
                      <option value="Diesel">Diesel</option>
                      <option value="Hybrid">Hybrid</option>
                      <option value="Electric">Electric</option>
                    </select>
                    <label className="form-label " htmlFor="sc-engine">
                      Engine <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select mb-3"
                      id="sc-engine"
                      defaultValue="2.5L Turbo 6 Cylinder"
                    >
                      <option value="" disabled>
                        Select engine
                      </option>
                      <option value="1.5 Turbo 3 Cylinder Engine">
                        1.5 Turbo 3 Cylinder Engine
                      </option>
                      <option value="2.4L 4 Cylinder Engine">
                        2.4L 4 Cylinder Engine
                      </option>
                      <option value="3.5L 4 Cylinder Engine">
                        3.5L 4 Cylinder Engine
                      </option>
                      <option value="2.5L Turbo 6 Cylinder">
                        2.5L Turbo 6 Cylinder
                      </option>
                    </select>
                    <label className="form-label " htmlFor="sc-transmission">
                      Transmission <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select mb-3"
                      id="sc-transmission"
                      defaultValue="Automatic"
                    >
                      <option value="" disabled>
                        Select transmission
                      </option>
                      <option value="Manual">Manual</option>
                      <option value="Automatic">
                        Automatic
                      </option>
                    </select>
                    <label className="form-label " htmlFor="sc-drivetrain">
                      Drivetrain <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select mb-3"
                      id="sc-drivetrain"
                      defaultValue="Front Wheel Drive"
                    >
                      <option value="" disabled>
                        Select drivetrain
                      </option>
                      <option value="Front Wheel Drive">
                        Front Wheel Drive
                      </option>
                      <option value="Rear Wheel Drive">Rear Wheel Drive</option>
                      <option value="All Wheel Drive">All Wheel Drive</option>
                    </select>
                    <label className="form-label " htmlFor="sc-color">
                      Color{" "}
                    </label>
                    <input
                      className="form-control mb-3"
                      type="text"
                      id="sc-color"
                      defaultValue="Aspen White"
                      placeholder="Enter color"
                    />
                  </div>
                </div>
              </div>
              <div className="border-top border-light mt-2 pt-4">
                <label className="form-label " htmlFor="sc-description">
                  Description{" "}
                </label>
                <textarea
                  className="form-control"
                  id="sc-description"
                  rows="5"
                  placeholder="Describe your vehicle"
                ></textarea>
                <span className="form-text  opacity-50">3000 characters left</span>
              </div>
            </section>
            {/* <!-- Features--> */}
            <section
              className="card border-0 bg-gray card-body p-4 mb-4"
              id="features"
            >
              <h2 className="h4  mb-4">
                <i className="fi-check-circle text-primary fs-5 mt-n1 me-2"></i>
                Features
              </h2>
              <div className="mb-4">
                <label className="form-label d-block  fw-bold mb-2 pb-1">
                  Exterior
                </label>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="alloy-wheels"
                      />
                      <label className="form-check-label" htmlFor="alloy-wheels">
                        Alloy wheels
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sunroof"
                      />
                      <label className="form-check-label" htmlFor="sunroof">
                        Sunroof / moonroof
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="panoramic-roof"
                      />
                      <label className="form-check-label" htmlFor="panoramic-roof">
                        Panoramic roof
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="tinted-glass"
                      />
                      <label className="form-check-label" htmlFor="tinted-glass">
                        Tinted glass
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="form-label d-block  fw-bold mb-2 pb-1">
                  Interior
                </label>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="ac" />
                      <label className="form-check-label" htmlFor="ac">
                        Multi-zone A/C
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="heated-seats"
                      />
                      <label className="form-check-label" htmlFor="heated-seats">
                        Heated front seats
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="navi"
                      />
                      <label className="form-check-label" htmlFor="navi">
                        Navigation system
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="leather-seats"
                      />
                      <label className="form-check-label" htmlFor="leather-seats">
                        Leather seats
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="wipers"
                      />
                      <label className="form-check-label" htmlFor="wipers">
                        Intermittent wipers
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="memory-seat"
                      />
                      <label className="form-check-label" htmlFor="memory-seat">
                        Memory seat
                      </label>
                    </div>
                  </div>
                </div>
                <div className="collapse" id="moreInterior">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="premium-sound"
                        />
                        <label className="form-check-label" htmlFor="premium-sound">
                          Premium sound system
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="bluetooth"
                        />
                        <label className="form-check-label" htmlFor="bluetooth">
                          Bluetooth
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="usb"
                        />
                        <label className="form-check-label" htmlFor="usb">
                          USB port
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="leather-wheel"
                        />
                        <label className="form-check-label" htmlFor="leather-wheel">
                          Leather steering wheel
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="apple-play"
                        />
                        <label className="form-check-label" htmlFor="apple-play">
                          Apple CarPlay
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="android-auto"
                        />
                        <label className="form-check-label" htmlFor="android-auto">
                          Android Auto
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="collapse-label collapsed fs-sm"
                  href="#moreInterior"
                  data-bs-toggle="collapse"
                  data-bs-label-collapsed="Show more"
                  data-bs-label-expanded="Show less"
                  role="button"
                  aria-expanded="false"
                  aria-controls="moreInterior"
                ></a>
              </div>
              <div className="mb-4">
                <label className="form-label d-block  fw-bold mb-2 pb-1">
                  Safety
                </label>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="airbag-driver"
                      />
                      <label className="form-check-label" htmlFor="airbag-driver">
                        Airbag: driver
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="airbag-pasenger"
                      />
                      <label className="form-check-label" htmlFor="airbag-pasenger">
                        Airbag: passenger
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="alarm"
                      />
                      <label className="form-check-label" htmlFor="alarm">
                        Alarm
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="antilock"
                      />
                      <label className="form-check-label" htmlFor="antilock">
                        Antilock brakes
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="stability-control"
                      />
                      <label className="form-check-label" htmlFor="stability-control">
                        Stability control
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fog-lights"
                      />
                      <label className="form-check-label" htmlFor="fog-lights">
                        Fog lights
                      </label>
                    </div>
                  </div>
                </div>
                <div className="collapse" id="moreSafety">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="adaptive-cruise"
                        />
                        <label className="form-check-label" htmlFor="adaptive-cruise">
                          Adaptive cruise control
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="blind-monitor"
                        />
                        <label className="form-check-label" htmlFor="blind-monitor">
                          Blind spot monitor
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="brake-assist"
                        />
                        <label className="form-check-label" htmlFor="brake-assist">
                          Brake assist
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="lane-warning"
                        />
                        <label className="form-check-label" htmlFor="lane-warning">
                          Lane departure warning
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  className="collapse-label collapsed fs-sm"
                  href="#moreSafety"
                  data-bs-toggle="collapse"
                  data-bs-label-collapsed="Show more"
                  data-bs-label-expanded="Show less"
                  role="button"
                  aria-expanded="false"
                  aria-controls="moreSafety"
                ></a>
              </div>
            </section>
            {/* <!-- Photos / video--> */}
            <section
              className="card border-0 bg-gray card-body p-4 mb-4"
              id="photos"
            >
              <h2 className="h4  mb-4">
                <i className="fi-image text-primary fs-5 mt-n1 me-2"></i>Photos /
                video
              </h2>
              <div
                className="alert alert-warning bg-faded-warning border-warning mb-4"
                role="alert"
              >
                <div className="d-flex">
                  <i className="fi-alert-circle me-2 me-sm-3"></i>
                  <p className="fs-sm mb-1">
                    The maximum photo size is 8 MB. Formats: jpeg, jpg, png. Put
                    the main picture first.
                    <br />
                    The maximum video size is 10 MB. Formats: mp4, mov.
                  </p>
                </div>
              </div>
              <input
                className="file-uploader file-uploader-grid bg-faded-light border-light"
                type="file"
                multiple
                data-max-file-size="10MB"
                accept="image/png, image/jpeg, video/mp4, video/mov"
                data-label-idle='&lt;div className="btn btn-primary mb-3"&gt;&lt;i className="fi-cloud-upload me-1"&gt;&lt;/i&gt;Upload photos / video&lt;/div&gt;&lt;div className=" opacity-70"&gt;or drag them in&lt;/div&gt;'
              />
            </section>
            {/* <!-- Location--> */}
            <section
              className="card bg-gray card-body border-0 p-4 mb-4"
              id="location"
            >
              <h2 className="h4  mb-4">
                <i className="fi-map-pin text-primary fs-5 mt-n1 me-2"></i>Location
              </h2>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-country">
                    Country / region <span className="text-danger">*</span>
                  </label>
                  <select
                    defaultValue="United States"
                    className="form-select"
                    id="sc-country"
                    required
                  >
                    <option value="" disabled>
                      Choose country
                    </option>
                    <option value="Australia">Australia</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Canada">Canada</option>
                    <option value="Germany">Germany</option>
                    <option value="United States">
                      United States
                    </option>
                  </select>
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-state">
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    id="sc-state"
                    required
                    defaultValue="Illinois"
                  >
                    <option value="" disabled>
                      Choose state
                    </option>
                    <option value="Alabama">Alabama</option>
                    <option value="California">California</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Illinois">
                      Illinois
                    </option>
                    <option value="Indiana">Indiana</option>
                    <option value="Kansas">Kansas</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 mb-3">
                  <label className="form-label " htmlFor="sc-city">
                    City <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    id="sc-city"
                    required
                    defaultValue="Chicago"
                  >
                    <option value="" disabled>
                      Choose city
                    </option>
                    <option value="Chicago">
                      Chicago
                    </option>
                    <option value="Dallas">Dallas</option>
                    <option value="Los Angeles">Los Angeles</option>
                    <option value="New York">New York</option>
                    <option value="San Diego">San Diego</option>
                  </select>
                </div>
                <div className="col-sm-4 mb-3">
                  <label className="form-label " htmlFor="sc-zip">
                    Zip code <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sc-zip"
                    placeholder="Enter Zip code"
                    defaultValue="60603"
                    required
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label " htmlFor="ap-address">
                  Street address <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="ap-address"
                  defaultValue="1904 S Michigan Avenue"
                  required
                />
              </div>
              
            </section>
            {/* <!-- Contacts--> */}
            <section
              className="card bg-gray card-body border-0 p-4 mb-4"
              id="contacts"
            >
              <h2 className="h4  mb-4">
                <i className="fi-phone text-primary fs-5 mt-n1 me-2"></i>Contacts
              </h2>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-fn">
                    First name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sc-fn"
                    defaultValue="Devon"
                    placeholder="Enter your first name"
                    required
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-sn">
                    Second name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sc-sn"
                    defaultValue="Lane"
                    placeholder="Enter your second name"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-email">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sc-email"
                    defaultValue="devone_lane@email.com"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label className="form-label " htmlFor="sc-phone">
                    Phone number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="tel"
                    id="sc-phone"
                    data-format="custom"
                    data-delimiter="-"
                    data-blocks="3 3 4"
                    defaultValue="316-442-0597"
                    placeholder="000-000-0000"
                  />
                </div>
              </div>
              <label className="form-label " htmlFor="sc-website">
                Website
              </label>
              <input
                className="form-control mb-3"
                type="text"
                id="sc-website"
                placeholder="Enter company name"
              />
              {/* <div className=" py-3">Social accounts</div>
              <div className="d-flex align-items-center mb-3">
                <div className="btn btn-icon btn-xs rounded-circle pe-none flex-shrink-0 me-3">
                  <i className="fi-facebook"></i>
                </div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your Facebook account"
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="btn btn-icon btn-translucent-light btn-xs rounded-circle pe-none flex-shrink-0 me-3">
                  <i className="fi-linkedin"></i>
                </div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your LinkedIn account"
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="btn btn-icon btn-translucent-light btn-xs rounded-circle pe-none flex-shrink-0 me-3">
                  <i className="fi-twitter"></i>
                </div>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Your Twitter account"
                />
              </div> */}
              
            </section>
            <div className="d-sm-flex justify-content-between pt-2">
              <a
                className="btn btn-outline-light btn-lg d-block mb-3 mb-sm-2"
                href="#preview-modal"
                data-bs-toggle="modal"
              >
                <i className="fi-eye-on ms-n1 me-2"></i>Preview
              </a>
              <a
                className="btn btn-primary btn-lg d-block mb-2"
                href="#"
              >
                Save and continue
              </a>
            </div>
          </div>
          {/* <!-- Progress of completion--> */}
          <aside className="col-lg-3 offset-lg-1 d-none d-lg-block">
            <div className="sticky-top pt-5">
              <h6 className=" pt-5 mt-3 mb-2">80% content filled</h6>
              <div
                className="progress progress-light mb-4"
                style={{ height: ".25rem" }}
              >
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: "80%" }}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <ul className="list-unstyled">
                <li className="d-flex align-items-center">
                  <i className="fi-check text-primary me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#basic-info"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Basic info
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check text-primary me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#price"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Price
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check text-primary me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#vehicle-info"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Vehicle information
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check  opacity-60 me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#features"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Features
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check  opacity-60 me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#photos"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Photos / video
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check text-primary me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#location"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Location
                  </a>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fi-check text-primary me-2"></i>
                  <a
                    className="nav-link ps-1"
                    href="#contacts"
                    data-scroll
                    data-scroll-offset="20"
                  >
                    Contacts
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}

export default SellCarPage;
