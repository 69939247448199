import Audi from "../../../assets/img/car-finder/brands/audi.svg";
import Honda from "../../../assets/img/car-finder/brands/honda.svg";
import Hyundai from "../../../assets/img/car-finder/brands/hyundai.svg";
import Infiniti from "../../../assets/img/car-finder/brands/infiniti.svg";
import Lexus from "../../../assets/img/car-finder/brands/lexus.svg";
import Mazda from "../../../assets/img/car-finder/brands/mazda.svg";
import Mercedes from "../../../assets/img/car-finder/brands/mercedes.svg";
import Mitsubishi from "../../../assets/img/car-finder/brands/mitsubishi.svg";
import Nissan from "../../../assets/img/car-finder/brands/nissan.svg";
import Opel from "../../../assets/img/car-finder/brands/opel.svg";
import Renault from "../../../assets/img/car-finder/brands/renault.svg";
import Toyota from "../../../assets/img/car-finder/brands/toyota.svg";

function Brands() {
  return (
    <section className="container py-2 py-sm-3">
      <div className="row g-2 g-sm-4">
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img src={Opel} width="86" alt="Opel" />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img src={Audi} width="86" alt="Audi" />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Mercedes}
              width="86"
              alt="Mercedes-Benz"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Toyota}
              width="86"
              alt="Toyota"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img src={Mazda} width="86" alt="Mazda" />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Nissan}
              width="86"
              alt="Nissan"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Hyundai}
              width="86"
              alt="Hyundai"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img src={Lexus} width="86" alt="Lexus" />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img src={Honda} width="86" alt="Honda" />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Renault}
              width="86"
              alt="Renault"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Infiniti}
              width="86"
              alt="Infiniti"
            />
          </a>
        </div>
        <div className="col-3 col-sm-2 col-xl-1 mb-4 pb-2">
          <a className="opacity-40 opacity-transition d-table mx-auto" href="#">
            <img
              src={Mitsubishi}
              width="86"
              alt="Mitsubishi"
            />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Brands;
