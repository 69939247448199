import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo/logo-light.svg";
import SignInImg from "../../assets/img/signin-modal/sign-in-illustration.svg";
import Avatar from "../../assets/img/avatars/01.jpg";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Header = () => {
  const [show, setShow] = useState(false);
  const [signupshow, setSignUpShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setSignUpShow(false);
    setShow(true);
  };

  const handleSignUpClose = () => setSignUpShow(false);
  const handleSignUpShow = () => {
    setSignUpShow(true);
    setShow(false);
  };

  return (
    <>
      <header
        className="navbar navbar-expand-lg fixed-top navbar-stuck bg-white"
        data-scroll-header
      >
        <div className="container">
          <a className="navbar-brand fw-bold fs-3 me-3 me-xl-4" href="#">
            {/* <img className="d-block" src={Logo} width="116" alt="Finder"/> */}
            <span>BidCars</span>
          </a>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="dropdown d-none d-lg-block order-lg-3 my-n2 me-3">
            <a className="d-block py-2" href="#">
              <img
                className="rounded-circle"
                src={Avatar}
                width="40"
                alt="Robert Fox"
              />
            </a>
            <div className="dropdown-menu dropdown-menu-dark dropdown-menu-end">
              <div
                className="d-flex align-items-start border-bottom border-light px-3 py-1 mb-2"
                style={{ width: "16rem" }}
              >
                <img
                  className="rounded-circle"
                  src={Avatar}
                  width="48"
                  alt="Robert Fox"
                />
                <div className="ps-2">
                  <h6 className="fs-base text-light mb-0">Robert Fox</h6>
                  <span className="star-rating star-rating-sm">
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                    <i className="star-rating-icon fi-star-filled active"></i>
                  </span>
                  <div className="fs-xs py-2">
                    (302) 555-0107
                    <br />
                    robert_fox@email.com
                  </div>
                </div>
              </div>
              <a className="dropdown-item" href="#">
                <i className="fi-user me-2"></i>Personal Info
              </a>
              <a className="dropdown-item" href="#">
                <i className="fi-lock me-2"></i>Password &amp; Security
              </a>
              <a className="dropdown-item" href="#">
                <i className="fi-car me-2"></i>My Cars
              </a>
              <a className="dropdown-item" href="#">
                <i className="fi-heart me-2"></i>Wishlist
                <span className="badge bg-faded-light ms-2">4</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="fi-star me-2"></i>Reviews
              </a>
              <a
                className="dropdown-item"
                href="#"
              >
                <i className="fi-bell me-2"></i>Notifications
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Help
              </a>
              <a className="dropdown-item" href="#">
                Sign Out
              </a>
            </div>
          </div>

          <a
            className="btn btn-link btn-sm d-none d-lg-block order-lg-3"
            onClick={handleShow}
            data-bs-toggle="modal"
          >
            <i className="fi-user me-2"></i>Sign in
          </a>
          {/* <a className="btn btn-primary btn-sm ms-2 order-lg-3" href="#"><i className="fi-plus me-2"></i>Sell car</a> */}
          <div className="collapse navbar-collapse order-lg-2" id="navbarNav">
            <ul
              className="navbar-nav navbar-nav-scroll"
              style={{ maxHeight: "35rem" }}
            >
              
              {/* <!-- Menu items--> */}
              <li className="nav-item active">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>

              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Auctions
                </a>
              </li>
              <li className="nav-item active">
                <Link to="sell-car" className="nav-link">
                  Sell Car
                </Link>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  About Us
                </a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Why BidCars?
                </a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Latest News
                </a>
              </li>
              <li className="nav-item d-lg-none">
                <a className="nav-link" onClick={handleShow} data-bs-toggle="modal">
                  <i className="fi-user me-2"></i>Sign in
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="p-0 bg-gray rounded overflow-hidden">
          <button
            onClick={handleClose}
            className="btn-close position-absolute top-0 end-0 mt-3 me-3"
            type="button"
            data-bs-dismiss="modal"
          ></button>
          <div className="row mx-0 align-items-center">
            <div className="col-md-6 p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
                Hey there!
                <br />
                Welcome back.
              </h2>
              <img
                className="d-block mx-auto"
                src={SignInImg}
                width="344"
                alt="Illustartion"
              />
              <div className="mt-4 mt-sm-5">
                <span className="opacity-60">Don't have an account? </span>
                <a
                  className="text-primary"
                  onClick={handleSignUpShow}
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Sign up here
                </a>
              </div>
            </div>
            <div className="col-md-6 bg-light border-start px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
              <a className="btn btn-outline-info w-100 mb-3" href="#">
                <i className="fi-google fs-lg me-1"></i>Sign in with Google
              </a>
              <a className="btn btn-outline-info w-100 mb-3" href="#">
                <i className="fi-facebook fs-lg me-1"></i>Sign in with Facebook
              </a>
              <div className="d-flex align-items-center py-3 mb-3">
                <hr className="w-100" />
                <div className="opacity-70 px-3">Or</div>
                <hr className="w-100" />
              </div>
              <form className="needs-validation" novalidate>
                <div className="mb-4">
                  <label className="form-label mb-2" for="signin-email">
                    Email address
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    id="signin-email"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <label className="form-label mb-0" for="signin-password">
                      Password
                    </label>
                    <a className="fs-sm" href="#">
                      Forgot password?
                    </a>
                  </div>
                  <div className="password-toggle">
                    <input
                      className="form-control"
                      type="password"
                      id="signin-password"
                      placeholder="Enter password"
                      required
                    />
                    <label
                      className="password-toggle-btn"
                      aria-label="Show/hide password"
                    >
                      <input className="password-toggle-check" type="checkbox" />
                      <span className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <button className="btn btn-primary btn-lg w-100" type="submit">
                  Sign in{" "}
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={signupshow}
        backdrop="static"
        keyboard={false}
        onHide={handleSignUpClose}
      >
        <Modal.Body className="p-0 bg-gray rounded overflow-hidden">
          <button
            onClick={handleSignUpClose}
            className="btn-close position-absolute top-0 end-0 mt-3 me-3"
            type="button"
            data-bs-dismiss="modal"
          ></button>
          <div className="row mx-0 align-items-center">
            <div className="col-md-7 p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
                Join BidCars.
                <br />
                Get premium benefits:
              </h2>
              <ul className="list-unstyled mb-4 mb-sm-5">
                <li className="d-flex mb-2">
                  <i className="fi-check-circle text-primary mt-1 me-2"></i>
                  <span>Add and promote your listings</span>
                </li>
                <li className="d-flex mb-2">
                  <i className="fi-check-circle text-primary mt-1 me-2"></i>
                  <span>Easily manage your wishlist</span>
                </li>
                <li className="d-flex mb-0">
                  <i className="fi-check-circle text-primary mt-1 me-2"></i>
                  <span>Leave reviews</span>
                </li>
              </ul>
              <img
                className="d-block mx-auto"
                src={SignInImg}
                width="344"
                alt="Illustartion"
              />
              <div className="mt-sm-4 pt-md-3">
                <span className="opacity-60">Already have an account? </span>
                <a
                  className="text-primary"
                  onClick={handleShow}
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Sign in
                </a>
              </div>
            </div>
            <div className="col-md-5 bg-light border-start px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
              <a className="btn btn-outline-info w-100 mb-3" href="#">
                <i className="fi-google fs-lg me-1"></i>Sign up with Google
              </a>
              <a className="btn btn-outline-info w-100 mb-3" href="#">
                <i className="fi-facebook fs-lg me-1"></i>Sign up with Facebook
              </a>
              <div className="d-flex align-items-center py-3 mb-3">
                <hr className="w-100" />
                <div className="opacity-70 px-3">Or</div>
                <hr className="w-100" />
              </div>
              <form className="needs-validation" novalidate>
                <div className="mb-4">
                  <label className="form-label" for="signup-name">
                    Full name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="signup-name"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-email">
                    Email address
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    id="signup-email"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-password">
                    Password <span className="fs-sm opacity-50">min. 8 char</span>
                  </label>
                  <div className="password-toggle">
                    <input
                      className="form-control"
                      type="password"
                      id="signup-password"
                      minlength="8"
                      required
                    />
                    <label
                      className="password-toggle-btn"
                      aria-label="Show/hide password"
                    >
                      <input className="password-toggle-check" type="checkbox" />
                      <span className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-password-confirm">
                    Confirm password
                  </label>
                  <div className="password-toggle">
                    <input
                      className="form-control"
                      type="password"
                      id="signup-password-confirm"
                      minlength="8"
                      required
                    />
                    <label
                      className="password-toggle-btn"
                      aria-label="Show/hide password"
                    >
                      <input className="password-toggle-check" type="checkbox" />
                      <span className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree-to-terms"
                    required
                  />
                  <label className="form-check-label" for="agree-to-terms">
                    <span className="opacity-70">By joining, I agree to the</span>{" "}
                    <a href="#" className="text-primary">
                      Terms of use
                    </a>{" "}
                    <span className="opacity-70">and</span>{" "}
                    <a href="#">Privacy policy</a>
                  </label>
                </div>
                <button className="btn btn-primary btn-lg w-100" type="submit">
                  Sign up{" "}
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
