import "./assets/sass/style.scss";
import Header from "./layouts/components/Header";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Footer from "./layouts/components/Footer";
import HomePage from "./views/homepage/HomePage";
import SellCarPage from "./views/seller/SellCar";
import PersonalInfo from "./views/account/PersonalInfo";
import AccountLayout from "./layouts/AccountLayout";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="sell-car" element={<SellCarPage />} />
            {/* <Route path="contact/*" element={<ContactPage />} /> */}
            <Route path="account/" element={<AccountLayout />}>
              <Route path="profile" element={<PersonalInfo />} />
              {/* <Route path="orders" element={<Orders />} />
              <Route path="billing" element={<Billing />} /> */}
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
    </div>
  );
}

export default App;
