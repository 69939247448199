import Sedan from "../../../assets/img/car-finder/icons/sedan.svg";
import Suv from "../../../assets/img/car-finder/icons/suv.svg";
import Wagon from "../../../assets/img/car-finder/icons/wagon.svg";
import CrossOver from "../../../assets/img/car-finder/icons/crossover.svg";
import Coupe from "../../../assets/img/car-finder/icons/coupe.svg";
import PickUp from "../../../assets/img/car-finder/icons/pickup.svg";
import Sport from "../../../assets/img/car-finder/icons/sport.svg";
import Compact from "../../../assets/img/car-finder/icons/compact.svg";
import Convertible from "../../../assets/img/car-finder/icons/convertible.svg";
import Mpv from "../../../assets/img/car-finder/icons/mpv.svg";

function BodyTypes() {
  return (
    <>
      {/* <!-- Body type selection--> */}
      <section className="container pb-5 mb-md-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-sm-4 pb-sm-2">
          <h2 className="h3 text-light mb-2 mb-sm-0">Popular car body types</h2>
          <a
            className="btn btn-link btn-light fw-normal px-0"
            href="#"
          >
            View all<i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
          </a>
        </div>
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-2 g-md-4">
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Sedan}
                width="160"
                alt="Sedan"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Sedan
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Suv}
                width="160"
                alt="SUV"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                SUV
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Wagon}
                width="160"
                alt="Wagon"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Wagon
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={CrossOver}
                width="160"
                alt="Crossover"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Crossover
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Coupe}
                width="160"
                alt="Coupe"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Coupe
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={PickUp}
                width="160"
                alt="Pickup"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Pickup
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Sport}
                width="160"
                alt="Sport Coupe"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Sport Coupe
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Compact}
                width="160"
                alt="Compact"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Compact
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Convertible}
                width="160"
                alt="Convertible"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Convertible
              </a>
            </div>
          </div>
          <div className="col">
            <div className="card card-body card-hover bg-transparent border-0 px-0 pt-0 text-center">
              <img
                className="d-block mx-auto mb-3"
                src={Mpv}
                width="160"
                alt="Family MPV"
              />
              <a
                className="nav-link stretched-link fw-bold"
                href="#"
              >
                Family MPV
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BodyTypes;
