import Catalog17 from "../../../assets/img/car-finder/catalog/17.jpg";
import Catalog09 from "../../../assets/img/car-finder/catalog/09.jpg";



function TopOffers() {
  return (
    <section className="container pt-sm-1 pb-5 mb-md-4">
      <div className="d-sm-flex align-items-center justify-content-between mb-4 pb-sm-2">
        <h2 className="h3 mb-2 mb-sm-0">Top offers</h2>
        <a
          className="btn btn-link fw-normal px-0"
          href="#"
        >
          View all offers
          <i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
        </a>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <div className="card card-hover h-100">
            <div className="tns-carousel-wrapper card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-info mb-1">New</span>
                <span className="d-table badge bg-success mb-1">$25,000</span>
                
              </div>
              <div className="content-overlay end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              {/* <div className="tns-carousel-inner">
                <img src={Catalog17} alt="Image" />
              </div> */}
              <div>
                <img src={Catalog17} alt="Image" />
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">2015</span>
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare1"
                  />
                  <label className="form-check-label fs-sm" for="compare1">
                    Compare
                  </label>
                </div> */}
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link" href="#">
                  Mercedes Benz Convertible Coupe
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$25,800</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>New York
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="border-top pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">48K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Automatic</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Gasoline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-hover card-horizontal mb-4">
            <div className="tns-carousel-wrapper card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute z-3 start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-info">3 Days</span>
                <span className="d-table badge bg-success mb-1">$25,000</span>
              </div>
              <div className="content-overlay position-absolute z-3 end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-light btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              <div className="top-0 position-absolute w-100 h-100">
                <div
                  className="bg-size-cover bg-position-center bg-size-cover w-100 h-100"
                  style={{
                    backgroundImage: "url(" + Catalog09 + ")",
                    backgroundPosition:"center",
                    backgroundSize: "cover"
                  }}
                ></div>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">1995</span>
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare2"
                  />
                  <label className="form-check-label fs-sm" for="compare2">
                    Compare
                  </label>
                </div> */}
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link" href="#">
                  Ford Truck Lifted{" "}
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$24,000</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>Chicago
              </div>
              <div className="border-top mt-3 pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">278K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Manual</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Diesel</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-hover card-horizontal">
            <div className="tns-carousel-wrapper card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute z-3 start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-danger">6 Days</span>
                <span className="d-table badge bg-success mb-1">$28,000</span>
              </div>
              <div className="content-overlay position-absolute z-3 end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              <div className="top-0 position-absolute w-100 h-100">
                <div
                  className="bg-size-cover bg-position-center w-100 h-100"
                  style={{
                    backgroundImage: "url(" + Catalog09 + ")",
                    backgroundPosition:"center",
                    backgroundSize: "cover"
                  }}
                ></div>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">2018</span>
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare3"
                  />
                  <label className="form-check-label fs-sm" for="compare3">
                    Compare
                  </label>
                </div> */}
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link" href="#">
                  BMW 640 XI Gran Turismo
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$43,500</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>San Francisco
              </div>
              <div className="border-top mt-3 pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">10K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Automatic</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Gasoline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopOffers;
