import Catalog8 from "../../../assets/img/car-finder/catalog/08.jpg";
import Catalog4 from "../../../assets/img/car-finder/catalog/04.jpg";

function LatestCars() {
  return (
    <section className="container pt-sm-5 pt-4 pb-3">
      <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-sm-4 pb-2">
        <h2 className="h3 mb-3 mb-sm-0">Latest cars</h2>
        <div className="d-flex align-items-center">
          <ul className="nav nav-tabs nav-tabs fs-sm me-4 pe-2 mb-0">
            <li className="nav-item">
              <a className="nav-link" href="#">
                New
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link active" href="#">
                Used
              </a>
            </li>
          </ul>
          <a
            className="btn btn-link btn-light fw-normal px-0"
            href="#"
          >
            View all<i className="fi-arrow-long-right fs-sm mt-0 ps-1 ms-2"></i>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="card card-hover h-100">
            <div className="card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-info">New</span>
              </div>
              <div className="content-overlay end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-light btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              <img src={Catalog8} alt="Image" />
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">2019</span>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare4"
                  />
                  <label className="form-check-label fs-sm" for="compare4">
                    Compare
                  </label>
                </div>
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link">
                  Nissan 370Z Nismo
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$37,900</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>New York
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="border-top pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">16K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Automatic</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Gasoline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card card-hover h-100">
            <div className="card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-info">1 Day</span>
              </div>
              <div className="content-overlay end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-light btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              <img src={Catalog4} alt="Image" />
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">2019</span>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare4"
                  />
                  <label className="form-check-label fs-sm" for="compare4">
                    Compare
                  </label>
                </div>
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link">
                  Nissan 370Z Nismo
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$37,900</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>New York
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="border-top pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">16K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Automatic</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Gasoline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card card-hover h-100">
            <div className="card-img-top card-img-hover">
              <a className="img-overlay" href="#"></a>
              <div className="position-absolute start-0 top-0 pt-3 ps-3">
                <span className="d-table badge bg-info">1 Day</span>
              </div>
              <div className="content-overlay end-0 top-0 pt-3 pe-3">
                <button
                  className="btn btn-icon btn-light btn-xs text-primary rounded-circle"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Add to Wishlist"
                >
                  <i className="fi-heart"></i>
                </button>
              </div>
              <img src={Catalog8} alt="Image" />
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between pb-1">
                <span className="fs-sm me-3">2019</span>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="compare4"
                  />
                  <label className="form-check-label fs-sm" for="compare4">
                    Compare
                  </label>
                </div>
              </div>
              <h3 className="h6 mb-1">
                <a className="nav-link">
                  Nissan 370Z Nismo
                </a>
              </h3>
              <div className="text-primary fw-bold mb-1">$37,900</div>
              <div className="fs-sm opacity-70">
                <i className="fi-map-pin me-1"></i>New York
              </div>
            </div>
            <div className="card-footer border-0 pt-0">
              <div className="border-top pt-3">
                <div className="row g-2">
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">16K mi</span>
                    </div>
                  </div>
                  <div className="col me-sm-1">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Automatic</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="bg-dark rounded text-center w-100 h-100 p-2">
                      <i className="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                      <span className="fs-xs text-light">Gasoline</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LatestCars;
