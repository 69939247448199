import { Outlet } from "react-router-dom";

const AccountLayout = () => {
  return (
    <section className="bg-secondary">
      <div className="container pt-5 mt-4 mt-lg-5 mt-xl-5">
        <div className="row pt-sm-2 pt-lg-0">
          {/* <AccountMenu /> */}
          {/* <!-- Page content --> */}
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default AccountLayout;
